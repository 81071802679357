<template>
  <div>

    <create-inventory></create-inventory>

    <inventories-table></inventories-table>

  </div>
    
</template>

<script>
import {
  BButton
} from 'bootstrap-vue'
import {constants} from "@/helpers/constants"
import CreateInventory from './CreateInventory.vue'
import InventoriesTable from './InventoriesTable.vue'

export default {
  components: {
    BButton,
    CreateInventory,
    InventoriesTable,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken')
    }
  },
  props: {
  },
  setup() {
  },
  created() {
  },
  methods: {
  
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <b-card title="Inventarios"
    :sub-title="'Total seriales: ' + formatCurrency(productQty)">

    <!-- <b-row>
      <b-col md="4">
        <b-card-text>NEO: 0</b-card-text>
      </b-col>
      <b-col md="4">
        <b-card-text>PLUS: 0</b-card-text>
      </b-col>
      <b-col md="4">
        <b-card-text>SMART: 0</b-card-text>
      </b-col>
    </b-row> -->


    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Buscar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Buscar"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="stowages"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'boxes_by_stowage'"
          class="text-nowrap"
        >
          {{ props.row.boxes_count }} / {{ props.row.boxes_by_stowage }}
        </span>

        <span
          v-else-if="props.column.field === 'finalized'"
        >
          {{ props.row.finalized == 1 ? 'Finalizada' : 'Pendiente' }}
        </span>

        <span
          v-else-if="props.column.field === 'available'"
        >
          {{ props.row.available == 1 ? 'Disponible' : 'No Disponible' }}
        </span>

        <span
          v-else-if="props.column.field === 'created_at'"
        >
          {{ formatDateVariant(props.row.created_at) }}
        </span>

        <span
          v-else-if="props.column.field === 'updated_at'"
        >
          {{ formatDateVariant(props.row.updated_at) }}
        </span>


        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="props.row.finalized == 0"
                :to="{ name: 'read-inventory-products', params: { stowage_id: props.row.id }}">
                <feather-icon
                  icon="ArrowRightIcon"
                  class="mr-50"
                />
                <span>Continuar</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'stowage-detail', params: { stowage_id: props.row.id }}">
                <feather-icon
                  icon="ListIcon"
                  class="mr-50"
                />
                <span>Detalle</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 a
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['20','40','60']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} items </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import {constants} from "@/helpers/constants"
import {formatDate} from "@/helpers/utils"


export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Estiba No.',
          field: 'stowage_number',
        },
        {
          label: 'Cant. de cajas',
          field: 'boxes_by_stowage',
        },
        {
          label: 'Tipo de serial',
          field: 'product_type.name',
        },
        {
          label: 'Finalizada',
          field: 'finalized',
        },
        {
          label: 'Estado',
          field: 'available',
        },
        {
          label: 'Creado',
          field: 'created_at',
        },
        {
          label: 'Actualizado',
          field: 'updated_at',
        },
        {
          label: 'Acciones',
          field: 'action',
        },
      ],
      stowages: [],
      productQty: 0,
      searchTerm: '',
      status: [{
        1: 'En alistamiento',
        2: 'Despachado',
        3: 'Cancelado',
        4: 'Finalizado',
        5: 'Recibido',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'En alistamiento'      : 'light-primary',
        Despachado : 'light-success',
        Cancelado     : 'light-danger',
        Finalizado     : 'light-warning',
        Recibido      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  created() {

    let headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.accessToken
    }
    axios.get(this.host + '/api/front-web/stowages-inventory', { headers }).then(response => {
      console.log('stowages-inventory: ', response)

      this.stowages = response.data.data.stowages
      this.productQty = response.data.data.product_qty
      
    }).catch( err => {
      console.log(err)
    })

  },
  methods: {
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat('es-ES', {
        style: 'decimal',
        minimumFractionDigits: 0
      });
      return formatter.format(parseInt(value, 10));
    },
  },
}
</script>
